import {defineStore} from 'pinia'

export const menuStore= defineStore('menu',{
    state:()=>({
        menuActive:[],
        menuDate :''// menu 实例 身上有 open close 方法
    }),
    
    actions:{
        setMenuClass(ref){
           this.menuDate = ref
           
        },
        openMenu(value){
            console.log(value);
            this.menuDate.open(value)
        },
        closeMenu(value){
            this.menuDate.close(value)

        }
    },
    persist: true
})