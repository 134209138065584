import axios from 'axios'
import router from '@/router/index'
const ajax = (addressurl, call, error, valbody = {}) => {
  let accouncode = 0
  let logcode = 1023
  let setcode = 1024

  let token_data = localStorage.getItem('token_data')

  let formData = new FormData() //创建form对象
  Object.keys(valbody).forEach(reo => {
    formData.append(reo, valbody[reo])
  })
  // VUE_APP_URL 环境变量
  axios.defaults.baseURL = process.env.VUE_APP_URL
  axios.defaults.headers.common['TSJFTOKEN'] = token_data //"75ebRqWFLcXE3SC3d0qkXTmn7cMDZsTDf7bc6dM/NEQ2N6jxhl5a/w7Qlt0Z"
  // axios.defaults.withCredentials=true;
  axios
    .post(addressurl, formData)
    .then(function (response) {
      let ret = response.data
      //成功失败
      if (ret.code == accouncode) {
        call(ret)
      } else {
        if (ret.code == logcode) {
          router.push({ name: 'login' })

          console.log('未登录')
          //未登录
          error(ret)
        } else if (ret.code == setcode) {
          //重新获取dtoken
          ajax(
            'Public_msg/getkeytoken',
            rem => {
              localStorage.setItem('token_data', rem.data)
              console.log(localStorage.getItem('token'))
              ajax(addressurl, call, error, valbody)
            },
            err => {
              error(err)
            },
            { token: localStorage.getItem('token') }
          )
        } else {
          error(ret)
        }
        //console.log("未成功返回:" + JSON.stringify(ret));
      }
    })
    .catch(function (error) {
      // console.log(error);
    })
}
// 按照惯例，组合式函数名以“use”开头
export { ajax }
