import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0698fb12"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "menu"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MyMenu = _resolveComponent("MyMenu");
  const _component_Content = _resolveComponent("Content");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_MyMenu, {
    isClose: $setup.isClose
  }, null, 8, ["isClose"])]), _createElementVNode("div", {
    class: _normalizeClass(["content", {
      active: $setup.isClose
    }])
  }, [_createVNode(_component_Content, {
    isClose: $setup.isClose,
    onChange: $setup.change
  }, null, 8, ["isClose", "onChange"])], 2)], 64);
}