import "core-js/modules/es.array.push.js";
import { onMounted, ref } from 'vue';
import dayjs from 'dayjs';
import { useRouter } from 'vue-router';
export default {
  props: ['isClose'],
  emits: ['change'],
  setup(props, {
    emit
  }) {
    //定义时间
    let time = ref(null);
    //路由
    const router = useRouter();
    //切换菜单折叠展开
    const change = () => {
      emit('change');
    };

    //生命周期函数
    onMounted(() => {
      //获取当前的时间
      time.value = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
    });

    //退出登录
    const out = () => {
      localStorage.clear();
      router.push('/login');
    };
    return {
      change,
      time,
      out
    };
  }
};