import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/message/style/css';
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["innerHTML"];
import { ajax } from '@/api/ajax.js';
import { reactive, ref } from 'vue';
export default {
  __name: 'index',
  setup(__props) {
    const xy = ref();
    const getData = () => {
      ajax("Public_msg/agreement", res => {
        xy.value = res.data;
      }, error => {
        ElMessage({
          type: 'error',
          message: error.msg
        });
      }, {
        type: 4
      });
    };
    getData();
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        innerHTML: xy.value
      }, null, 8, _hoisted_1);
    };
  }
};