export default {
  data() {
    return {};
  },
  created() {
    // console.log(this.$route.matched);
  },
  computed: {
    navData() {
      let matche = this.$route.matched;
      matche[0].path = '/';
      return matche;
    }
  }
};