import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");
  return _openBlock(), _createBlock(_component_el_breadcrumb, {
    separator: "/"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.navData, item => {
      return _openBlock(), _createBlock(_component_el_breadcrumb_item, {
        key: item.path,
        to: {
          path: item.path
        }
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(item.meta.title), 1)]),
        _: 2
      }, 1032, ["to"]);
    }), 128))]),
    _: 1
  });
}