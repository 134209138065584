import { createRouter, createWebHistory,createWebHashHistory } from "vue-router";
import Layout from "@/views/Layout";
import Login from "@/views/Login";
import Xy from "@/views/Xy";
//() => import('../views/AboutView.vue')
import Home from "@/views/Home";
import update from "@/views/Update";
//产品
const Goods = () => import("@/views/Goods");
const List = () => import("@/views/Goods/list/GoodsList.vue");
const Category = () => import("@/views/Goods/category/Category.vue");
const AddGoods = () => import("@/views/Goods/list/AddGoods.vue");
const AddGoodsgg = () => import("@/views/Goods/list/AddGoodsgg.vue");


//订单
const order = ()=>import('@/views/Order')
const orderlist = ()=>import('@/views/Order/list')
const collect = ()=>import('@/views/Order/collect')


const routes = [
  {
    path: "/",
    component: Layout,
    meta:{
      title:"首页"
    },
    children: [
      {
        path: "/",
        name: "home",
        component: Home,
        meta:{
          title:"首页"
        },
      },
      {
        path: "/goods",
        component: Goods,
        redirect:'/goods/list',
        meta:{
          title:"产品管理"
        },
        children: [
          {
            path: "list",
            name: "list",
            component: List,
            meta:{
              title:"产品列表"
            },
          },
          {
            path: "category",
            name: "category",
            component: Category,
            meta:{
              title:"产品分类"
            },
          },
          {
            path: "addgoods",
            name: "addgoods",
            component: AddGoods,
            //路由元信息
            meta: {
              isShow: true,
              activeMenu: "/goods/list",
              title: "添加商品",
            },
          },
          {
            path: "goodsgg",
            name: "goodsgg",
            component: ()=>import('@/views/Goods/list/Goodsgg.vue'),
            //路由元信息
            meta: {
              isShow: true,
              activeMenu: "/goods/list",
              title: "规格编辑",
            },
          },

          {
            path: "addgoodsgg",
            name: "addgoodsgg",
            component: AddGoodsgg,
            //路由元信息
            meta: {
              isShow: true,
              activeMenu: "/goods/list",
              title: "修改规格",
            },
          },
        ],
      },
      {
        path:'/order',
        component:order,
        redirect:'/order/orderlist',
        meta:{
          title:'订单管理'
        },
        children:[
          {
            path:'orderlist',
            component:orderlist,
            meta:{
              title:'订单列表'
            }
          },
          {
            path:'collect',
            component:collect,
            meta:{
              title:'汇总清单'
            }
          }
        ]
      },
      {
        path: "/update",
        name: "update",
        component: update,
        meta:{
          title:"上传更新"
        },
      },
    ],
  },

  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/xy",
    name: "xy",
    component: Xy,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes,
// });

export default router;
