import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "normalize.css";
import "./assets/css/base.css";
import "element-plus/theme-chalk/el-message.css";
import "element-plus/theme-chalk/el-message-box.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
import axios  from "axios";
axios.defaults.baseURL="/api";
app.provide('$axios', axios);

// import JsonExcel from "vue-json-excel"; //vue-json-excel
// app.component("downloadExcel", JsonExcel);


//挂载全局---
import echarts from "@/plugins/echarts";
//方法1.
app.config.globalProperties.$echarts = echarts;
//方法2：
app.provide("echarts", echarts);
import ElementPlus from "element-plus";
import locale from "element-plus/lib/locale/lang/zh-cn"; //需要新加的代码
app.use(ElementPlus, { locale }); //需要改变的地方，加入locale
//导入pinia
import { createPinia } from "pinia";
import piniapluginpersistedstate from 'pinia-plugin-persistedstate'
const pinia = createPinia();
pinia.use(piniapluginpersistedstate);
app.use(pinia);
//导入面包屑组件
import Breadcrumb from "@/components/Breadcrumb";
app.component("Breadcrumb", Breadcrumb);

app.use(router).mount("#app");
