import MyMenu from './MyMenu';
import Content from './Content';
import { ref } from 'vue';
export default {
  components: {
    MyMenu,
    Content
  },
  setup() {
    //定义变量
    let isClose = ref(false);

    //修改变量
    const change = () => {
      isClose.value = !isClose.value;
    };
    return {
      isClose,
      change
    };
  }
};